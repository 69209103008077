import React from 'react';
import styled from 'styled-components';

import { colors } from '../../components/styledComponents';
import { Container, Content } from '../../components/home/homeStyledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';

const TermsContent = styled.div`
  padding: 20px;
  color: ${colors.navy};
`;

const A = styled.a`
  color: ${colors.navy};
  text-decoration: none;
`;

function renderLinks() {
  return (
    <>
      <A
        target="_blank"
        rel="noreferrer noopener"
        href="/CGU_Tilli_20220114.pdf"
      >
        {' nos CGU '}
      </A>
      |
      <A
        target="_blank"
        rel="noreferrer noopener"
        href="/CGV_Tilli_20220114.pdf"
      >
        {' nos CGV '}
      </A>
      |
      <A
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.mangopay.com/terms/PSP/PSP_MANGOPAY_FR.pdf"
      >
        {' CGU de Mangopay '}
      </A>
      |
      <A
        target="_blank"
        rel="noreferrer noopener"
        href="/Règlement%20Jeu-concours%20Nouveau%20Modèle%20Podcast.pdf"
      >
        {' Règlement jeu concours Nouveau Modèle '}
      </A>
    </>
  );
}

const LegalTerms = () => (
  <Layout routeSlug="LegalTerms">
    <Container>
      <Content>
        <MenuBar />
        <TermsContent>
          <h1>Mentions légales</h1>
          <h2>Éditeur</h2>
          <p>
            {`
            Le présent site web, accessible à partir de l'adresse URL www.tilli.fr (ci-après le « Site Web ») est édité
            par la société Tilli, société par actions simplifiée immatriculée au Registre du Commerce et des Sociétés
            sous le numéro 825038938 RCS Paris, dont le siège social est situé 19 boulevard St Denis 75002 Paris
            (ci-après "Tilli").
          `}
          </p>

          <p>Le Directeur de la publication du Site Web est Madame Beryl de Labouchere.</p>

          <h2>Hébergement</h2>
          <p>L’hébergeur du Site Web est Netlify Inc.</p>

          <h2>Conditions d’utilisation du Site Web</h2>
          <p>
            {`
            En accédant et/ou en utilisant le Site Web, chaque personne physique (ci-après l’« Utilisateur ») déclare et
            garantit qu'elle a pris préalablement connaissance des présentes informations légales et des Conditions
            générales d'utilisation du Site Web et qu'elle en accepte les termes et conditions sans réserve,
            modification ou restriction.
            `}
          </p>

          {renderLinks()}
        </TermsContent>
        <Footer />
      </Content>
    </Container>
  </Layout>
);

export default LegalTerms;
